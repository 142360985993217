"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.research = void 0;
exports.research = {
    '0up6oJCPP3M9bDYx34Et': {
        _created: '2022-03-27T22:08:25.999Z',
        _createdBy: 'event_reader',
        _deleted: false,
        _id: '0up6oJCPP3M9bDYx34Et',
        _modified: '2022-03-27T22:10:11.271Z',
        _contentModifiedTimestamp: '2022-03-27T22:10:11.271Z',
        creatorCountry: 'ge',
        description: 'qwertyefew',
        moderation: 'accepted',
        slug: 'qwerty',
        previousSlugs: ['qwerty'],
        tags: {
            h1wCs0o9j60lkw3AYPB1: true,
        },
        title: 'qwerty',
        updates: [
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'ERO3RibAuvz7Wt12LfTb',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
                files: [
                    {
                        timeCreated: '2019-05-10T20:49:42.878Z',
                        name: 'art final 2.skp',
                        fullPath: 'uploads/howtosV1/me5Bq0wq5FdoJUY8gELN/art final 2.skp',
                        type: '',
                        updated: '2019-05-10T20:49:42.878Z',
                        size: 647225,
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/onearmyworld.appspot.com/o/uploads%2FhowtosV1%2Fme5Bq0wq5FdoJUY8gELN%2Fart%20final%202.skp?alt=media&token=f4ebe379-2038-4ac0-9e6f-68c379174908',
                        contentType: 'application/octet-stream',
                    },
                ],
                downloadCount: 2555,
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
            {
                _created: '2022-03-27T22:10:11.271Z',
                _deleted: false,
                _id: 'random-id',
                _modified: '2022-03-27T22:10:11.271Z',
                description: 'qwerty',
                images: [
                    {
                        contentType: 'image/jpeg',
                        downloadUrl: 'https://firebasestorage.googleapis.com/v0/b/precious-plastics-v4-dev.appspot.com/o/uploads%2Fresearch%2F0up6oJCPP3M9bDYx34Et%2F1426018318_414579695-17fcd6de5f7.jpg?alt=media&token=9f18315b-a1ad-410b-b31c-2161fb1d7142',
                        fullPath: 'uploads/research/0up6oJCPP3M9bDYx34Et/1426018318_414579695-17fcd6de5f7.jpg',
                        name: '1426018318_414579695-17fcd6de5f7.jpg',
                        size: 109985,
                        timeCreated: '2022-03-27T22:10:10.106Z',
                        type: 'image/jpeg',
                        updated: '2022-03-27T22:10:10.106Z',
                    },
                ],
                title: 'qwerty',
                comments: [
                    {
                        _id: 'abc123',
                        _created: '2019-09-18T14:49:54.311Z',
                        _creatorId: 'abc',
                        creatorName: 'abc',
                        text: 'A generated comment',
                    },
                ],
            },
        ],
    },
    '0up6oJCTT3M9bDYx34Et': {
        _created: '2023-02-27T22:08:25.999Z',
        _createdBy: 'demo_user',
        _deleted: false,
        _id: '0up6oJCTT3M9bDYx34Et',
        _modified: '2023-03-01T19:12:11.271Z',
        creatorCountry: 'it',
        description: 'A test!',
        moderation: 'accepted',
        slug: 'a-test-research',
        previousSlugs: ['a-test-research'],
        tags: {
            h1wCs0o9j60lkw3AYPB1: true,
        },
        title: 'A test research',
    },
    '0up6oJCYY3M9bDYx34Et': {
        _created: '2023-02-27T22:08:25.999Z',
        _createdBy: 'demo_user',
        _deleted: true,
        _id: '0up6oJCYY3M9bDYx34Et',
        _modified: '2023-03-01T19:12:11.271Z',
        creatorCountry: 'it',
        description: 'A deleted research test!',
        moderation: 'accepted',
        slug: 'a-deleted-test-research',
        previousSlugs: ['a-deleted-test-research'],
        tags: {
            h1wCs0o9j60lkw3AYPB1: true,
        },
        title: 'A deleted test research',
    },
};
