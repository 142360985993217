"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationTypes = exports.EmailNotificationFrequency = void 0;
var EmailNotificationFrequency;
(function (EmailNotificationFrequency) {
    EmailNotificationFrequency["NEVER"] = "never";
    EmailNotificationFrequency["DAILY"] = "daily";
    EmailNotificationFrequency["WEEKLY"] = "weekly";
    EmailNotificationFrequency["MONTHLY"] = "monthly";
})(EmailNotificationFrequency || (exports.EmailNotificationFrequency = EmailNotificationFrequency = {}));
exports.NotificationTypes = [
    'new_comment',
    'howto_useful',
    'howto_mention',
    'howto_approved',
    'howto_needs_updates',
    'map_pin_approved',
    'map_pin_needs_updates',
    'new_comment_discussion',
    'new_comment_research',
    'research_useful',
    'research_mention',
    'research_update',
    'research_approved',
    'research_needs_updates',
];
