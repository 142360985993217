"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResearchUpdateStatus = exports.ResearchStatus = void 0;
var ResearchStatus;
(function (ResearchStatus) {
    ResearchStatus["IN_PROGRESS"] = "In progress";
    ResearchStatus["COMPLETED"] = "Completed";
    ResearchStatus["ARCHIVED"] = "Archived";
})(ResearchStatus || (exports.ResearchStatus = ResearchStatus = {}));
var ResearchUpdateStatus;
(function (ResearchUpdateStatus) {
    ResearchUpdateStatus["DRAFT"] = "draft";
    ResearchUpdateStatus["PUBLISHED"] = "published";
})(ResearchUpdateStatus || (exports.ResearchUpdateStatus = ResearchUpdateStatus = {}));
