"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DifficultyLevel = void 0;
var DifficultyLevel;
(function (DifficultyLevel) {
    DifficultyLevel["EASY"] = "Easy";
    DifficultyLevel["MEDIUM"] = "Medium";
    DifficultyLevel["HARD"] = "Hard";
    DifficultyLevel["VERY_HARD"] = "Very Hard";
})(DifficultyLevel || (exports.DifficultyLevel = DifficultyLevel = {}));
