"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FollowButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const Button_1 = require("../Button/Button");
const Tooltip_1 = require("../Tooltip/Tooltip");
const FollowButton = (props) => {
    const { hasUserSubscribed, isLoggedIn, onFollowClick, sx } = props;
    const navigate = (0, react_router_dom_1.useNavigate)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Button_1.Button, { "data-testid": isLoggedIn ? 'follow-button' : 'follow-redirect', "data-cy": isLoggedIn ? 'follow-button' : 'follow-redirect', "data-tip": isLoggedIn ? '' : 'Login to follow', icon: "thunderbolt", variant: "outline", iconColor: hasUserSubscribed ? 'subscribed' : 'notSubscribed', sx: {
                    fontSize: 2,
                    py: 0,
                    ...sx,
                }, onClick: () => (isLoggedIn ? onFollowClick() : navigate('/sign-in')), children: hasUserSubscribed ? 'Following' : 'Follow' }), (0, jsx_runtime_1.jsx)(Tooltip_1.Tooltip, {})] }));
};
exports.FollowButton = FollowButton;
