"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentStatistics = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const theme_ui_1 = require("theme-ui");
const Button_1 = require("../Button/Button");
const Icon_1 = require("../Icon/Icon");
const ContentStatistics = (props) => {
    const [showStats, setShowStats] = (0, react_1.useState)(false);
    const handleShowStats = () => {
        setShowStats(!showStats);
    };
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { "data-cy": 'ContentStatistics', py: 1, sx: {
            alignItems: ['flex-start', 'center', 'center'],
            justifyContent: 'center',
            gap: 2,
            flexDirection: ['column', 'row', 'row'],
            pl: [2, 0, 0],
        }, children: [(0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    display: ['flex', 'none', 'none'],
                    width: '100%',
                    pr: 2,
                    pl: 2,
                }, onClick: handleShowStats, children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: {
                            fontSize: '13px',
                        }, children: showStats ? '' : 'More Information' }), (0, jsx_runtime_1.jsx)(Button_1.Button, { variant: 'subtle', showIconOnly: true, icon: showStats ? 'chevron-up' : 'chevron-down', small: true, sx: {
                            bg: 'white',
                            borderWidth: '0px',
                            '&:hover': {
                                bg: 'white',
                            },
                            '&:active': {
                                bg: 'white',
                            },
                        } })] }), props.statistics.map((statistic, idx) => ((0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { px: 2, py: 1, mb: 1, sx: {
                    alignItems: 'center',
                    fontSize: '1',
                    display: [showStats ? 'flex' : 'none', 'flex', 'flex'],
                }, children: [(0, jsx_runtime_1.jsx)(Icon_1.Icon, { glyph: statistic.icon, mr: 1, size: 'sm', opacity: '0.5' }), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { children: statistic.label })] }, idx)))] }));
};
exports.ContentStatistics = ContentStatistics;
