"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionCategories = void 0;
exports.questionCategories = {
    LSH1ehdIE36hWyk3Ockr: {
        _modified: '2012-10-27T01:47:57.948Z',
        _created: '2012-08-02T07:27:04.609Z',
        _id: 'categoryLSH1ehdIE36hWyk3Ockr',
        label: 'product',
        _deleted: false,
    },
    Lmj5B5UJh0M8BxSTP3uI: {
        _modified: '2018-07-29T04:34:49.982Z',
        _created: '2017-11-20T05:58:20.458Z',
        _id: 'categoryLmj5B5UJh0M8BxSTP3uI',
        label: 'exhibition',
        _deleted: false,
    },
    oN1tULWYIN9P1ytOfdDQ: {
        _deleted: false,
        _modified: '2018-05-19T04:57:18.471Z',
        _created: '2017-10-29T07:29:17.905Z',
        _id: 'categoryoN1tULWYIN9P1ytOfdDQ',
        label: 'compression',
    },
    oix4r6grC1mMA0Xz3K: {
        _modified: '2017-01-19T07:07:12.730Z',
        _created: '2015-02-23T23:04:03.609Z',
        _id: 'categoryoix4r6grC1mMA0Xz3K',
        label: 'screening',
        _deleted: false,
    },
};
