"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IModerationStatus = void 0;
var IModerationStatus;
(function (IModerationStatus) {
    IModerationStatus["DRAFT"] = "draft";
    IModerationStatus["AWAITING_MODERATION"] = "awaiting-moderation";
    IModerationStatus["IMPROVEMENTS_NEEDED"] = "improvements-needed";
    IModerationStatus["REJECTED"] = "rejected";
    IModerationStatus["ACCEPTED"] = "accepted";
})(IModerationStatus || (exports.IModerationStatus = IModerationStatus = {}));
