"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsefulStatsButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_2 = require("@emotion/react");
const theme_ui_1 = require("theme-ui");
const Button_1 = require("../Button/Button");
const Tooltip_1 = require("../Tooltip/Tooltip");
const UsefulStatsButton = (props) => {
    const theme = (0, react_2.useTheme)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [votedUsefulCount, setVotedUsefulCount] = (0, react_1.useState)();
    const [hasUserVotedUseful, setHasUserVotedUseful] = (0, react_1.useState)();
    const [disabled, setDisabled] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => setHasUserVotedUseful(props.hasUserVotedUseful), [props.hasUserVotedUseful]);
    (0, react_1.useEffect)(() => setVotedUsefulCount(props.votedUsefulCount || 0), [props.votedUsefulCount]);
    (0, react_1.useEffect)(() => setDisabled(props.disabled), [props.disabled]);
    const handleUsefulClick = () => {
        setDisabled(true);
        props.onUsefulClick();
        setDisabled(false);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(Button_1.Button, { "data-tip": props.isLoggedIn ? '' : 'Login to add your vote', "data-cy": props.isLoggedIn ? 'vote-useful' : 'vote-useful-redirect', onClick: () => props.isLoggedIn ? handleUsefulClick() : navigate('/sign-in'), disabled: disabled, sx: {
                    fontSize: 2,
                    backgroundColor: theme.colors.white,
                    py: 0,
                    '&:hover': {
                        backgroundColor: theme.colors.softblue,
                    },
                    ...props.sx,
                }, icon: hasUserVotedUseful ? 'star' : 'star-active', children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Text, { pr: 2, py: 2, sx: {
                            display: 'inline-block',
                        }, children: votedUsefulCount }), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { pl: 2, py: 2, sx: {
                            display: 'inline-block',
                            borderLeft: `1px solid ${theme.colors.black}`,
                        }, children: hasUserVotedUseful ? 'Marked as useful' : 'Mark as useful' })] }), (0, jsx_runtime_1.jsx)(Tooltip_1.Tooltip, {})] }));
};
exports.UsefulStatsButton = UsefulStatsButton;
