"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscussionTitle = exports.COMMENTS = exports.ONE_COMMENT = exports.NO_COMMENTS = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
exports.NO_COMMENTS = 'Start the discussion';
exports.ONE_COMMENT = '1 Comment';
exports.COMMENTS = 'Comments';
const DiscussionTitle = ({ length }) => {
    const setTitle = () => {
        if (length === 0) {
            return exports.NO_COMMENTS;
        }
        if (length === 1) {
            return exports.ONE_COMMENT;
        }
        return `${length} ${exports.COMMENTS}`;
    };
    const title = setTitle();
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Heading, { as: "h3", sx: { marginBottom: 2 }, children: title }));
};
exports.DiscussionTitle = DiscussionTitle;
