"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapMemberCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@emotion/react");
const theme_ui_1 = require("theme-ui");
const Username_1 = require("../Username/Username");
const wave = (0, react_1.keyframes) `
  from {
    background: lightgrey;
  }
  to {
    background: grey;
  }
`;
const MapMemberCard = (props) => {
    const { imageUrl, description, user, heading, comments } = props;
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Card, { sx: { maxWidth: '230px' }, "data-cy": "MapMemberCard", children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Box, { children: [!!props.loading && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(theme_ui_1.AspectRatio, { ratio: 230 / 120, sx: {
                                background: 'lightgrey',
                                animation: `${wave} 800ms ease infinite`,
                            } }), (0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: { p: 2, height: '109px' } })] })), !props.loading && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(theme_ui_1.AspectRatio, { ratio: 230 / 120, sx: {
                                background: 'lightgrey',
                            }, children: (0, jsx_runtime_1.jsx)(theme_ui_1.Image, { src: imageUrl, sx: { objectFit: 'cover', width: '100%', height: '100%' } }) }), (0, jsx_runtime_1.jsxs)(theme_ui_1.Box, { sx: { p: 2 }, children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Text, { mb: 2, sx: { fontSize: '12px', color: 'blue' }, children: heading }), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(Username_1.Username, { user: user }) }), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { mb: 2, sx: {
                                        wordBreak: 'break-word',
                                        fontSize: 1,
                                        display: 'block',
                                        color: 'black',
                                    }, children: description }), comments ? ((0, jsx_runtime_1.jsx)(theme_ui_1.Alert, { variant: "info", sx: { fontSize: 1, textAlign: 'left' }, "data-testid": "MapMemberCard: moderation comments", children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Box, { children: ["This map pin has been marked as requiring further changes.", (0, jsx_runtime_1.jsx)(theme_ui_1.Box, { children: comments })] }) })) : null] })] }))] }) }));
};
exports.MapMemberCard = MapMemberCard;
