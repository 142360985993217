"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.users = exports.tags = exports.research = exports.questions = exports.questionCategories = exports.mappins = exports.howtos = exports.discussions = exports.categories = void 0;
var categories_1 = require("./categories");
Object.defineProperty(exports, "categories", { enumerable: true, get: function () { return categories_1.categories; } });
var discussions_1 = require("./discussions");
Object.defineProperty(exports, "discussions", { enumerable: true, get: function () { return discussions_1.discussions; } });
var howtos_1 = require("./howtos");
Object.defineProperty(exports, "howtos", { enumerable: true, get: function () { return howtos_1.howtos; } });
var mappins_1 = require("./mappins");
Object.defineProperty(exports, "mappins", { enumerable: true, get: function () { return mappins_1.mappins; } });
var questionCategories_1 = require("./questionCategories");
Object.defineProperty(exports, "questionCategories", { enumerable: true, get: function () { return questionCategories_1.questionCategories; } });
var questions_1 = require("./questions");
Object.defineProperty(exports, "questions", { enumerable: true, get: function () { return questions_1.questions; } });
var research_1 = require("./research");
Object.defineProperty(exports, "research", { enumerable: true, get: function () { return research_1.research; } });
var tags_1 = require("./tags");
Object.defineProperty(exports, "tags", { enumerable: true, get: function () { return tags_1.tags; } });
var users_1 = require("./users");
Object.defineProperty(exports, "users", { enumerable: true, get: function () { return users_1.users; } });
