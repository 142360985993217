"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateReply = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const theme_ui_1 = require("theme-ui");
const CreateComment_1 = require("../CreateComment/CreateComment");
const CreateReply = (props) => {
    const [reply, setReply] = (0, react_1.useState)('');
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [isError, setIsError] = (0, react_1.useState)(false);
    const { commentId, isLoggedIn, maxLength, onSubmit } = props;
    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            await onSubmit(commentId, reply);
            setReply('');
            setIsLoading(false);
        }
        catch (error) {
            // Swallow the error for now
            setIsLoading(false);
            setIsError(true);
        }
    };
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Box, { sx: {
            background: 'softblue',
            borderRadius: 2,
            marginBottom: 3,
            padding: 3,
        }, children: [(0, jsx_runtime_1.jsx)(CreateComment_1.CreateComment, { maxLength: maxLength, comment: reply, onChange: (text) => setReply(text), onSubmit: handleSubmit, isLoggedIn: isLoggedIn, isLoading: isLoading, buttonLabel: "Leave a reply" }), isError ? ((0, jsx_runtime_1.jsx)(theme_ui_1.Alert, { variant: "failure", sx: { mt: 3 }, children: "Unable to leave a comment at this time. Please try again later." })) : null] }));
};
exports.CreateReply = CreateReply;
