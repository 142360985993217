"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadFileFromLink = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const ExternalLink_1 = require("../ExternalLink/ExternalLink");
const Icon_1 = require("../Icon/Icon");
const Tooltip_1 = require("../Tooltip/Tooltip");
const DownloadButton = (props) => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { p: 2, mb: 1, sx: {
                    background: 'accent.base',
                    border: '2px solid black',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    maxWidth: '300px',
                    borderRadius: 1,
                }, onClick: () => props.redirectToSignIn && props.redirectToSignIn(), "data-tip": props.redirectToSignIn ? 'Login to download' : '', children: [(0, jsx_runtime_1.jsx)(Icon_1.Icon, { size: 24, glyph: 'external-url', mr: 3 }), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: { flex: 1, fontSize: 1, color: 'black' }, mr: 3, children: "Download files" })] }), (0, jsx_runtime_1.jsx)(Tooltip_1.Tooltip, {})] }));
};
const DownloadFileFromLink = (props) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: !props.redirectToSignIn ? ((0, jsx_runtime_1.jsx)(ExternalLink_1.ExternalLink, { href: props.link, onClick: () => props.handleClick && props.handleClick(), children: (0, jsx_runtime_1.jsx)(DownloadButton, {}) })) : ((0, jsx_runtime_1.jsx)(DownloadButton, { redirectToSignIn: props.redirectToSignIn })) }));
};
exports.DownloadFileFromLink = DownloadFileFromLink;
