"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const Tag_1 = require("../Tag/Tag");
const TagList = ({ tags }) => {
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { sx: { gap: 1 }, children: tags.map((tag, index) => ((0, jsx_runtime_1.jsx)(Tag_1.Tag, { tag: tag }, index))) }));
};
exports.TagList = TagList;
