"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const Tag = (props) => {
    const { tag, sx } = props;
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: {
            fontSize: 1,
            color: 'blue',
            ...sx,
            '::before': {
                content: '"#"',
            },
        }, children: tag.label }));
};
exports.Tag = Tag;
