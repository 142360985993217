"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.centerAspectCrop = void 0;
const react_image_crop_1 = require("react-image-crop");
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return (0, react_image_crop_1.centerCrop)((0, react_image_crop_1.makeAspectCrop)({
        unit: '%',
        width: 90,
    }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}
exports.centerAspectCrop = centerAspectCrop;
