"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscussionContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const theme_ui_1 = require("theme-ui");
const CommentList_1 = require("../CommentList/CommentList");
const CreateComment_1 = require("../CreateComment/CreateComment");
const DiscussionTitle_1 = require("../DiscussionTitle/DiscussionTitle");
const transformToStructuredComments_1 = require("./transformToStructuredComments");
const DiscussionContainer = (props) => {
    const { comment, comments, handleDelete, handleEdit, handleEditRequest, onSubmitReply, highlightedCommentId, maxLength, onChange, onMoreComments, onSubmit, isLoggedIn, supportReplies = false, } = props;
    const [commentBeingRepliedTo, setCommentBeingRepliedTo] = (0, react_1.useState)(null);
    const structuredComments = (0, react_1.useMemo)(() => (0, transformToStructuredComments_1.transformToTree)(comments), [comments]);
    const handleSetCommentBeingRepliedTo = (commentId) => {
        if (commentId === commentBeingRepliedTo) {
            return setCommentBeingRepliedTo(null);
        }
        setCommentBeingRepliedTo(commentId);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(DiscussionTitle_1.DiscussionTitle, { length: comments.length }), (0, jsx_runtime_1.jsx)(CommentList_1.CommentList, { supportReplies: supportReplies, comments: structuredComments, handleDelete: handleDelete, handleEdit: handleEdit, handleEditRequest: handleEditRequest, highlightedCommentId: highlightedCommentId, isLoggedIn: isLoggedIn, isReplies: false, maxLength: maxLength, onMoreComments: onMoreComments, onSubmitReply: onSubmitReply, setCommentBeingRepliedTo: handleSetCommentBeingRepliedTo }), (0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { sx: {
                    alignItems: 'stretch',
                    background: 'softblue',
                    borderRadius: 2,
                    flexDirection: 'column',
                    padding: 3,
                }, children: (0, jsx_runtime_1.jsx)(CreateComment_1.CreateComment, { maxLength: maxLength, comment: comment, onChange: onChange, onSubmit: onSubmit, isLoggedIn: isLoggedIn }) })] }));
};
exports.DiscussionContainer = DiscussionContainer;
