"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questions = void 0;
exports.questions = {
    '3s7Fyn6Jf8ryANJM6Jf6': {
        _created: '2024-03-14T10:57:20.113Z',
        _createdBy: 'demo_user',
        _deleted: false,
        _id: '3s7Fyn6Jf8ryANJM6Jf6',
        _modified: '2024-03-15T15:14:25.029Z',
        commentCount: 3,
        creatorCountry: '',
        description: 'test info with a link to https://www.onearmy.earth/',
        keywords: ['test', 'question', 'info'],
        latestCommentDate: '15 March 2024 at 15:14:24 UTC',
        moderation: 'accepted',
        questionCategory: {
            _created: '2024-01-10T09:00:00.000Z',
            _deleted: false,
            _id: 'test',
            _modified: '2024-01-10T09:00:00.000Z',
            label: 'test category',
        },
        slug: 'the-first-test-question',
        tags: {
            KP3McutTpuEWz06G5EY1: true,
            Wk6RnHHFfKSiI71BlM8r: true,
        },
        title: 'The first test question',
        total_views: 3,
        votedUsefulBy: [
            'demo_user',
            'notification_triggerer',
            'settings_workplace_new',
            'mapview_testing_rejected',
        ],
        subscribers: ['demo_user', 'benfurber'],
    },
    dsvihbefwbhiewrbiy: {
        _created: '2024-03-14T10:57:20.113Z',
        _createdBy: 'demo_user',
        _deleted: false,
        _id: 'dsvihbefwbhiewrbiy',
        _modified: '2024-03-18T15:14:25.029Z',
        commentCount: 3,
        creatorCountry: '',
        description: 'This is a test mock for the filtering question.',
        keywords: ['filtering', 'info'],
        latestCommentDate: '20 March 2024 at 10:10:10 UTC',
        moderation: 'accepted',
        questionCategory: {
            _deleted: false,
            _modified: '2018-05-19T04:57:18.471Z',
            _created: '2017-10-29T07:29:17.905Z',
            _id: 'categoryoN1tULWYIN9P1ytOfdDQ',
            label: 'compression',
        },
        slug: 'filtering-question',
        tags: {
            KP3McutTpuEWz06G5EY1: true,
            Wk6RnHHFfKSiI71BlM8r: true,
        },
        title: 'The Filtering Question',
        total_views: 43,
        votedUsefulBy: ['mapview_testing_rejected'],
        subscribers: ['benfurber'],
    },
    y731rib324: {
        _created: '2024-03-14T10:57:20.113Z',
        _createdBy: 'benfurber',
        _deleted: false,
        _id: 'y731rib324',
        _modified: '2024-03-18T15:14:25.029Z',
        commentCount: 0,
        creatorCountry: '',
        description: "What's the deal with sreenings?",
        keywords: ['screening', 'question', 'intro'],
        latestCommentDate: '20 March 2024 at 10:10:10 UTC',
        moderation: 'accepted',
        questionCategory: {
            _modified: '2017-01-19T07:07:12.730Z',
            _created: '2015-02-23T23:04:03.609Z',
            _id: 'categoryoix4r6grC1mMA0Xz3K',
            label: 'screening',
            _deleted: false,
        },
        slug: 'whats-the-deal-with-screenings',
        tags: {
            KP3McutTpuEWz06G5EY1: true,
        },
        title: 'Intro screenings question',
        total_views: 1,
        votedUsefulBy: ['mapview_testing_rejected'],
        subscribers: [],
    },
};
