"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockedRoute = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const Button_1 = require("../Button/Button");
const InternalLink_1 = require("../InternalLink/InternalLink");
const BlockedRoute = (props) => {
    const redirectLabel = props.redirectLabel || 'Back to home';
    const redirectUrl = props.redirectUrl || '/';
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: { justifyContent: 'center', flexDirection: 'column', mt: 8 }, "data-cy": "BlockedRoute", children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: { width: '100%', textAlign: 'center' }, children: props.children }), (0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: { textAlign: 'center', mt: 2 }, children: (0, jsx_runtime_1.jsx)(InternalLink_1.InternalLink, { to: redirectUrl, children: (0, jsx_runtime_1.jsx)(Button_1.Button, { variant: 'subtle', small: true, children: redirectLabel }) }) })] }));
};
exports.BlockedRoute = BlockedRoute;
