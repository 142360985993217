"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditComment = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_final_form_1 = require("react-final-form");
const theme_ui_1 = require("theme-ui");
const Button_1 = require("../Button/Button");
const FieldTextarea_1 = require("../FieldTextarea/FieldTextarea");
const EditComment = (props) => {
    const { comment, isReply } = props;
    return ((0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: () => {
            // do nothing
        }, initialValues: {
            comment,
        }, render: ({ handleSubmit, values }) => ((0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { as: "form", sx: {
                flexDirection: 'column',
            }, p: 2, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsxs)(theme_ui_1.Label, { as: "label", htmlFor: "comment", sx: { marginBottom: '6px', fontSize: 3 }, children: ["Edit ", isReply ? 'Reply' : 'Comment'] }), (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { component: FieldTextarea_1.FieldTextarea, "data-cy": "edit-comment", id: "comment", name: "comment" }), (0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { mt: 4, ml: "auto", children: [(0, jsx_runtime_1.jsx)(Button_1.Button, { small: true, mr: 4, variant: "secondary", onClick: () => props?.handleCancel(), children: "Cancel" }), (0, jsx_runtime_1.jsx)(Button_1.Button, { "data-cy": "edit-comment-submit", type: 'submit', "aria-label": "Save changes", small: true, onClick: () => {
                                props?.handleSubmit(values.comment);
                            }, children: "Save" })] })] })) }));
};
exports.EditComment = EditComment;
