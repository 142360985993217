"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadCounter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
// Duplicated util from main app - should be in 'shared' once the setup
// is right with typing and testing
const numberWithCommas = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};
const DownloadCounter = ({ total }) => {
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { "data-cy": "file-download-counter", sx: {
            fontSize: 1,
            color: 'grey',
        }, children: [numberWithCommas(total || 0), total !== 1 ? ' downloads' : ' download'] }));
};
exports.DownloadCounter = DownloadCounter;
