"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.discussions = void 0;
exports.discussions = {
    '06fjiZWeyxxMkE0brp6n': {
        _created: '2022-02-10T20:22:15.500Z',
        _deleted: false,
        _id: '06fjiZWeyxxMkE0brp6n',
        _modified: '2022-02-10T20:22:15.500Z',
        comments: [
            {
                _created: '2022-02-10T20:22:15.500Z',
                _creatorId: 'Q1sgtnNTPBSYCKEXCOWaOMcPqZD3',
                _edited: '2022-02-10T20:22:15.500Z',
                _id: 'ZAB2SoUZtzUyeYLg9Cro',
                creatorCountry: 'af',
                creatorName: 'howto_creator',
                parentCommentId: null,
                text: "i like your logo but I couldn't be conected for a while and I CANNOT tell you that anymore.",
            },
            {
                _created: '2023-02-10T20:22:15.500Z',
                _creatorId: 'TPBSYCKEXCOWaOMcPqZD3Q1sgtnN',
                _id: 'ZtzUyeYLgZAB2SoU9Cro',
                creatorCountry: 'uk',
                creatorName: 'benfurber',
                parentCommentId: null,
                text: "Love what you're doing. Keep it up.",
            },
            {
                _created: '2023-02-11T02:22:15.500Z',
                _creatorId: 'TPBSYCKEXCOWaOMcPqZD3Q1sgtnN',
                _id: 'BtzUAB2SoU9CroyeYLgZ',
                creatorCountry: 'uk',
                creatorName: 'benfurber',
                parentCommentId: 'ZAB2SoUZtzUyeYLg9Cro',
                text: 'Annoyingly, I think I agree with @howto_creator.',
            },
            {
                _created: '2023-02-12T02:22:19.500Z',
                _edited: '2023-02-12T02:22:43.500Z',
                _creatorId: 'TPBSYCKEXCOWaOMcPqZD3Q1sgtnN',
                _id: 'Bt9CroyeYLgZ55555',
                creatorCountry: '',
                creatorName: 'davehakkens',
                parentCommentId: 'ZAB2SoUZtzUyeYLg9Cro',
                text: 'Me too @benfurber!',
            },
            {
                _created: '2023-02-15T02:45:15.500Z',
                _creatorId: 'TPBSYCKEXCOWaOMcPqZD3Q1sgtnN',
                _id: 'BtzUAB2SoU9CroyeYLgZ',
                creatorCountry: 'uk',
                creatorName: 'benfurber',
                parentCommentId: 'ZAB2SoUZtzUyeYLg9Cro',
                text: '<3',
            },
        ],
        contributorIds: [
            'Q1sgtnNTPBSYCKEXCOWaOMcPqZD3',
            'TPBSYCKEXCOWaOMcPqZD3Q1sgtnN',
        ],
        sourceId: '3s7Fyn6Jf8ryANJM6Jf6',
        sourceType: 'question',
    },
};
