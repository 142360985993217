"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalLink = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable no-restricted-imports */
const react_router_dom_1 = require("react-router-dom");
const theme_ui_1 = require("theme-ui");
const InternalLink = (props) => {
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Link, { as: react_router_dom_1.Link, ...props, children: props.children }));
};
exports.InternalLink = InternalLink;
