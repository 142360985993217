"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentList = exports.CommentContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const theme_ui_1 = require("theme-ui");
const Button_1 = require("../Button/Button");
const ButtonShowReplies_1 = require("../ButtonShowReplies/ButtonShowReplies");
const CommentItem_1 = require("../CommentItem/CommentItem");
const CreateReply_1 = require("../CreateReply/CreateReply");
const Icon_1 = require("../Icon/Icon");
const MAX_COMMENTS = 5;
const CommentContainer = (props) => {
    const [isShowReplies, setIsShowReplies] = (0, react_1.useState)(false);
    const { comment, handleDelete, handleEditRequest, handleEdit, supportReplies, isLoggedIn, isReplies, maxLength, onSubmitReply, } = props;
    const { _id, creatorName, replies } = comment;
    const replyArrow = () => {
        return ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: { paddingTop: 4 }, children: (0, jsx_runtime_1.jsx)(Icon_1.Icon, { glyph: "arrow-curved-bottom-right" }) }));
    };
    const repliesButton = () => {
        return ((0, jsx_runtime_1.jsx)(ButtonShowReplies_1.ButtonShowReplies, { creatorName: creatorName, isShowReplies: isShowReplies, replies: replies || [], setIsShowReplies: () => setIsShowReplies(!isShowReplies) }));
    };
    const createReply = () => {
        if (onSubmitReply) {
            return ((0, jsx_runtime_1.jsx)(CreateReply_1.CreateReply, { commentId: _id, isLoggedIn: isLoggedIn, maxLength: maxLength, onSubmit: onSubmitReply }));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Box, { sx: {
            backgroundColor: 'white',
            borderRadius: 1,
            padding: 3,
        }, children: [(0, jsx_runtime_1.jsx)(CommentItem_1.CommentItem, { comment: comment, handleEditRequest: handleEditRequest, handleDelete: handleDelete, handleEdit: handleEdit, isReply: isReplies ? true : false }), supportReplies && !isShowReplies && repliesButton(), supportReplies && isShowReplies ? ((0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: {
                    alignItems: 'stretch',
                    flexDirection: 'row',
                }, children: [replies && replyArrow(), (0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: {
                            alignItems: 'stretch',
                            flexDirection: 'column',
                            flex: 1,
                        }, children: [(0, jsx_runtime_1.jsx)(exports.CommentList, { comments: replies || [], handleDelete: handleDelete, handleEdit: handleEdit, handleEditRequest: handleEditRequest, isLoggedIn: isLoggedIn, isReplies: true, maxLength: maxLength, supportReplies: false }), createReply(), repliesButton()] })] })) : null] }));
};
exports.CommentContainer = CommentContainer;
const CommentList = (props) => {
    const { comments, handleDelete, handleEdit, handleEditRequest, highlightedCommentId, isLoggedIn, isReplies, maxLength, onMoreComments, onSubmitReply, supportReplies = false, } = props;
    const [moreComments, setMoreComments] = (0, react_1.useState)(1);
    const shownComments = moreComments * MAX_COMMENTS;
    const scrollIntoRelevantComment = (commentId) => {
        setTimeout(() => {
            // the delay is needed, otherwise the scroll is not happening in Firefox
            document
                .getElementById(`comment:${commentId}`)
                ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
    };
    const handleMoreComments = () => {
        onMoreComments && onMoreComments();
        setMoreComments(moreComments + 1);
    };
    (0, react_1.useEffect)(() => {
        if (!highlightedCommentId)
            return;
        const i = comments.findIndex((comment) => highlightedCommentId.includes(comment._id));
        if (i >= 0) {
            setMoreComments(Math.floor(i / MAX_COMMENTS) + 1);
            scrollIntoRelevantComment(highlightedCommentId);
        }
    }, [highlightedCommentId, comments]);
    return ((0, jsx_runtime_1.jsxs)(theme_ui_1.Box, { mb: 4, sx: {
            width: '100%',
            display: 'block',
        }, children: [comments &&
                comments.slice(0, shownComments).map((comment) => ((0, jsx_runtime_1.jsx)(theme_ui_1.Box, { "data-testid": "CommentList: item", sx: {
                        marginBottom: 4,
                        border: `${highlightedCommentId === comment._id
                            ? '2px dashed black'
                            : 'none'}`,
                        borderRadius: 1,
                    }, children: (0, jsx_runtime_1.jsx)(exports.CommentContainer, { comment: comment, handleEditRequest: handleEditRequest, handleDelete: handleDelete, handleEdit: handleEdit, isLoggedIn: isLoggedIn, isReplies: isReplies, maxLength: maxLength, onSubmitReply: onSubmitReply, supportReplies: supportReplies }) }, comment._id))), comments && comments.length > shownComments && ((0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { children: (0, jsx_runtime_1.jsx)(Button_1.Button, { sx: {
                        margin: '0 auto',
                    }, variant: "outline", onClick: handleMoreComments, children: "show more comments" }) }))] }));
};
exports.CommentList = CommentList;
