"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const Button_1 = require("../Button/Button");
const Modal_1 = require("../Modal/Modal");
const ConfirmModal = (props) => {
    const { message, confirmButtonText, isOpen } = props;
    return ((0, jsx_runtime_1.jsx)(Modal_1.Modal, { onDidDismiss: () => props?.handleCancel, isOpen: isOpen, children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Box, { "data-cy": "Confirm.modal: Modal", children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Text, { children: message }), (0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { p: 2, mx: -1, sx: { justifyContent: 'flex-end' }, children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { px: 1, children: (0, jsx_runtime_1.jsx)(Button_1.Button, { variant: 'outline', onClick: () => props?.handleCancel(), children: "Cancel" }) }), (0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { px: 1, children: (0, jsx_runtime_1.jsx)(Button_1.Button, { "aria-label": `Confirm ${confirmButtonText} action`, "data-cy": "Confirm.modal: Confirm", variant: 'outline', onClick: () => props?.handleConfirm(), children: confirmButtonText }) })] })] }) }));
};
exports.ConfirmModal = ConfirmModal;
