"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconCountWithTooltip = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const Icon_1 = require("../Icon/Icon");
const Tooltip_1 = require("../Tooltip/Tooltip");
const IconCountWithTooltip = (props) => {
    const { count, dataCy, icon, text } = props;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(theme_ui_1.Text, { "data-cy": dataCy, "data-tip": text, color: "black", sx: {
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    fontSize: [1, 2, 2],
                }, children: [count, (0, jsx_runtime_1.jsx)(Icon_1.Icon, { glyph: icon, ml: 1 })] }), (0, jsx_runtime_1.jsx)(Tooltip_1.Tooltip, {})] }));
};
exports.IconCountWithTooltip = IconCountWithTooltip;
