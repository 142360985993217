"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalLinkLabel = exports.UserRole = void 0;
/* eslint-disable @typescript-eslint/naming-convention */
var UserRole;
(function (UserRole) {
    UserRole["SUPER_ADMIN"] = "super-admin";
    UserRole["SUBSCRIBER"] = "subscriber";
    UserRole["ADMIN"] = "admin";
    UserRole["BETA_TESTER"] = "beta-tester";
    UserRole["RESEARCH_EDITOR"] = "research_editor";
    UserRole["RESEARCH_CREATOR"] = "research_creator";
})(UserRole || (exports.UserRole = UserRole = {}));
var ExternalLinkLabel;
(function (ExternalLinkLabel) {
    ExternalLinkLabel["EMAIL"] = "email";
    ExternalLinkLabel["WEBSITE"] = "website";
    ExternalLinkLabel["DISCORD"] = "discord";
    ExternalLinkLabel["BAZAR"] = "bazar";
    ExternalLinkLabel["FORUM"] = "forum";
    ExternalLinkLabel["SOCIAL_MEDIA"] = "social media";
    ExternalLinkLabel["FACEBOOK"] = "facebook";
    ExternalLinkLabel["INSTAGRAM"] = "instagram";
})(ExternalLinkLabel || (exports.ExternalLinkLabel = ExternalLinkLabel = {}));
