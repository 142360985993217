"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonShowReplies = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Button_1 = require("../Button/Button");
const ButtonShowReplies = (props) => {
    const { creatorName, isShowReplies, replies, setIsShowReplies } = props;
    const length = replies && replies.length ? replies.length : 0;
    const icon = isShowReplies ? 'arrow-full-up' : 'arrow-full-down';
    const text = length
        ? `${length} ${length === 1 ? 'reply' : 'replies'}`
        : `Reply`;
    return ((0, jsx_runtime_1.jsx)(Button_1.Button, { "data-cy": `show-replies`, icon: icon, onClick: setIsShowReplies, sx: { alignSelf: 'flex-start' }, variant: "outline", small: true, children: `${text} to ${creatorName}` }));
};
exports.ButtonShowReplies = ButtonShowReplies;
