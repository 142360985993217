"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldTextarea = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const theme_ui_1 = require("theme-ui");
const CharacterCount_1 = require("../CharacterCount/CharacterCount");
const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);
const processInputModifiers = (value, modifiers) => {
    if (typeof value !== 'string')
        return value;
    if (modifiers.trim) {
        value = value.trim();
    }
    if (modifiers.capitalize) {
        value = capitalizeFirstLetter(value);
    }
    return value;
};
const FieldTextarea = ({ input, meta, disabled, modifiers, customOnBlur, minLength = 0, maxLength, showCharacterCount, rows, ...rest }) => {
    const [curLength, setLength] = (0, react_1.useState)(input?.value?.length ?? 0);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(theme_ui_1.Textarea, { disabled: disabled, minLength: minLength, maxLength: maxLength, variant: meta?.error && meta?.touched ? 'textareaError' : 'textarea', rows: rows ? rows : 5, sx: {
                    resize: rest?.style?.resize ? rest.style.resize : 'vertical',
                }, ...input, ...rest, onBlur: (e) => {
                    if (modifiers) {
                        e.target.value = processInputModifiers(e.target.value, modifiers);
                        input.onChange(e);
                    }
                    if (customOnBlur) {
                        customOnBlur(e);
                    }
                    input.onBlur();
                }, onChange: (ev) => {
                    showCharacterCount && setLength(ev.target.value.length);
                    input.onChange(ev);
                } }), meta.error && meta.touched && ((0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: { fontSize: 0, margin: 1, color: 'error' }, children: meta.error })), showCharacterCount && maxLength && (meta.touched || meta.dirty) && ((0, jsx_runtime_1.jsx)(CharacterCount_1.CharacterCount, { minSize: minLength, maxSize: maxLength, currentSize: curLength }))] }));
};
exports.FieldTextarea = FieldTextarea;
