"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadStaticFile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const ExternalLink_1 = require("../ExternalLink/ExternalLink");
const Icon_1 = require("../Icon/Icon");
const Tooltip_1 = require("../Tooltip/Tooltip");
const FileDetails = (props) => {
    const { file, glyph, size, redirectToSignIn } = props;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { p: 2, mb: 1, sx: {
                    borderRadius: 1,
                    border: '2px solid black',
                    background: 'accent.base',
                    color: 'black',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '300px',
                    cursor: 'pointer',
                }, onClick: () => redirectToSignIn && redirectToSignIn(), "data-tip": redirectToSignIn ? 'Login to download' : '', children: [(0, jsx_runtime_1.jsx)(Icon_1.Icon, { size: 24, glyph: glyph, mr: 3 }), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: {
                            flex: 1,
                            fontSize: 1,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }, mr: 3, children: file.name }), (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: { fontSize: 1 }, children: size })] }), (0, jsx_runtime_1.jsx)(Tooltip_1.Tooltip, {})] }));
};
const DownloadStaticFile = ({ file, allowDownload, handleClick, redirectToSignIn, }) => {
    const size = bytesToSize(file.size || 0);
    if (!file) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: allowDownload && file.downloadUrl && !redirectToSignIn ? ((0, jsx_runtime_1.jsx)(ExternalLink_1.ExternalLink, { m: 1, onClick: () => handleClick && handleClick(), href: file.downloadUrl, download: file.name, style: { width: '300px', marginLeft: 0 }, children: (0, jsx_runtime_1.jsx)(FileDetails, { file: file, glyph: "download-cloud", size: size }) })) : ((0, jsx_runtime_1.jsx)(FileDetails, { file: file, glyph: "download-cloud", size: size, redirectToSignIn: redirectToSignIn })) }));
};
exports.DownloadStaticFile = DownloadStaticFile;
const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
        return '0 Bytes';
    }
    const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toPrecision(3) + ' ' + sizes[i];
};
