"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Category = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_ui_1 = require("theme-ui");
const Category = (props) => {
    const { category, sx } = props;
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { sx: { alignItems: 'start' }, children: (0, jsx_runtime_1.jsx)(theme_ui_1.Text, { sx: {
                fontSize: 1,
                color: '#555555',
                backgroundColor: '#cccccc',
                paddingX: '7.5px',
                paddingY: '5px',
                borderRadius: 1,
                ...sx,
            }, children: category.label }) }));
};
exports.Category = Category;
