"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@emotion/react");
const theme_ui_1 = require("theme-ui");
const Icon_1 = require("../Icon/Icon");
function getIconByType(type) {
    if (['howto_useful', 'research_useful'].includes(type))
        return 'useful';
    if (['howto_approved', 'map_pin_approved', 'research_approved'].includes(type))
        return 'check';
    if ([
        'howto_needs_updates',
        'map_pin_needs_updates',
        'research_needs_updates',
    ].includes(type))
        return 'edit';
    if (type === 'research_update')
        return 'thunderbolt';
    return 'comment';
}
const NotificationItem = (props) => {
    const { type } = props;
    return ((0, jsx_runtime_1.jsx)(theme_ui_1.Flex, { bg: "white", "data-cy": "notification", sx: {
            flexDirection: 'column',
            fontSize: '12px',
            marginBottom: 2,
            paddingBottom: 2,
            borderBottom: '1px solid #c7c7c7',
            fontFamily: 'Inter, sans-serif',
        }, children: (0, jsx_runtime_1.jsx)(react_1.ThemeProvider, { theme: {
                styles: {
                    a: {
                        textDecoration: 'underline',
                        color: '#61646b',
                        display: 'inline',
                        '&:hover': {
                            textDecoration: 'none',
                        },
                    },
                },
            }, children: (0, jsx_runtime_1.jsxs)(theme_ui_1.Flex, { sx: { gap: 1, flexDirection: 'row' }, children: [(0, jsx_runtime_1.jsx)(Icon_1.Icon, { glyph: getIconByType(type), size: 15 }), (0, jsx_runtime_1.jsx)(theme_ui_1.Box, { sx: { textAlign: 'left' }, children: props.children })] }) }) }));
};
exports.NotificationItem = NotificationItem;
